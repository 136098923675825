/* theme.less file that will be compiled */
img {
  border: 0;
  max-width: 100%;
  height: auto;
  width: auto\9;
  vertical-align: bottom;
}
.outer-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
.col100 {
  width: 100%;
  margin: 0 auto;
}
.col75 {
  width: 73%;
  float: left;
  margin-right: 2%;
}
#side-contents {
  width: 25%;
  float: left;
  padding-top: 2.4em;
}
@media screen and (max-width: 640px) {
  .col100,
  .col75,
  .col50,
  .col25,
  #side-contents {
    margin: 0 auto;
    width: 98%;
    float: none;
  }
}
body {
  background: #ffffff;
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: #4d4d4d;
}
a {
  outline: 0;
  text-decoration: none;
}
a:link,
a:visited {
  color: #0061a3;
}
a:hover,
a:active {
  color: #69af6f;
}
a.state-private {
  color: #c4183c !important;
}
h1 {
  color: #333333;
  font-size: 26px;
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 500;
  font-style: normal;
  border-bottom: 1px solid #cccccc;
}
h2 {
  margin: 1.2em 0 0.8em;
  font-size: 22px;
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 400;
  font-style: normal;
}
h2.gray {
  color: #4d4d4d;
}
h2.green {
  border-bottom: 1px solid #69af6f;
  color: #18ab42;
}
h2.blu {
  border-bottom: 1px solid #009499;
  color: #009499;
}
h3 {
  margin: 1.2em 0 0.5em;
  font-size: 20px;
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 500;
  font-style: normal;
}
h3.gray {
  color: #4d4d4d;
}
h3.blu {
  color: #009499;
}
h4 {
  margin: 1em 0 0.5em;
  font-size: 18px;
  font-family: source-han-sans-japanese, sans-serif;
  font-weight: 500;
  font-style: normal;
}
h4.black {
  color: #2f2933;
}
h4.blu {
  color: #0061a3;
}
h4.gw {
  color: #ffffff;
  background: #88b04b;
  padding: 0.4em 0.2em;
}
h4.gws {
  color: #ffffff;
  background: #88b04b;
  padding: 0.4em;
  font-size: 14px;
}
h5,
h6 {
  margin: 1em 0 0.5em;
  font-size: 16px;
}
.sqbox {
  border: 1px solid #cccccc;
  padding: 0.8em;
  margin-bottom: 1em;
}
.sqboxb {
  border: 1px solid #cccccc;
  padding: 0.8em;
  margin-bottom: 1em;
  border-width: 5px;
}
.rbox {
  border: 1px solid #cccccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 0.8em;
}
.grayWhite {
  border: 1px solid #cccccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 0.8em;
  background-color: #666666;
  color: #ffffff;
}
#header {
  margin: 0px auto;
  padding: 0;
  max-width: 1280px;
}
#header #logo {
  float: left;
  display: inline-block;
  margin: 1em 0;
  background-image: url("++theme++ricerco/images/logo.png");
  background-repeat: no-repeat;
  -webkit-background-size: 194px 60px;
  -moz-background-size: 194px 60px;
  background-size: 194px 60px;
  width: 194px;
  height: 60px;
}
#header #logo span {
  visibility: hidden;
}
#header #gnav {
  margin: 1em 0 0 350px;
  padding: 30px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}
#header #gnav li {
  line-height: 2em;
  padding: 0 1em;
}
#header #gnav li a {
  display: block;
  text-decoration: none;
  color: #333333;
}
#header #gnav li a:hover {
  color: #cccccc;
}
@media screen and (max-width: 768px) {
  #header #logo {
    float: none;
    margin-bottom: 0;
  }
  #header #gnav {
    margin: 1em 0 0;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  #header {
    width: 96%;
  }
  #header #logo {
    float: none;
  }
  #header #gnav li {
    padding: 0 0.5em;
  }
}
.keyimage {
  display: block;
  margin: 0 0 1em;
}
#main-contents {
  margin: 0 auto;
  padding: 0 1em;
  max-width: 1280px;
}
#main-contents #portal-breadcrumbs ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
#main-contents #portal-breadcrumbs ol li {
  display: inline-block;
}
#main-contents #portal-breadcrumbs ol li::after {
  content: '/';
  line-height: 1.5;
  font-size: 80%;
}
#main-contents p {
  line-height: 1.7;
}
#main-contents p.small {
  font-size: 12px;
  color: #4d4d4d;
}
#main-contents ul,
#main-contents ol {
  margin-bottom: 3em;
}
#main-contents ul li,
#main-contents ol li {
  margin-bottom: 0.5em;
}
#main-contents .boxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
#main-contents .boxes .box {
  border: 1px solid #cccccc;
  margin: 1%;
  padding: 2%;
  flex: 1;
}
#main-contents .boxes .box2 {
  border: 1px solid #cccccc;
  margin: 1%;
  padding: 2%;
  flex: 0 1 43.5%;
}
#main-contents .boxes .box3 {
  border: 1px solid #cccccc;
  margin: 1%;
  padding: 2%;
  flex: 0 1 27%;
}
#main-contents .boxes .box4 {
  border: 1px solid #cccccc;
  margin: 1%;
  padding: 2%;
  flex: 0 1 18%;
}
#main-contents table.tableinvisible {
  border: none;
}
#main-contents table.tableinvisible tbody th,
#main-contents table.tableinvisible tfoot th,
#main-contents table.tableinvisible thead th,
#main-contents table.tableinvisible tbody td,
#main-contents table.tableinvisible tfoot td,
#main-contents table.tableinvisible thead td {
  border: none;
}
#main-contents table.tableheader tbody th,
#main-contents table.tableheader tfoot th,
#main-contents table.tableheader thead th {
  background-color: #f1f1f1;
  font-weight: bold;
}
#main-contents table th,
#main-contents table td {
  padding: 0.5em 1em;
}
#main-contents dl {
  margin: 0 1em;
}
#main-contents dl.corporate-info {
  margin-bottom: 4em;
}
#main-contents dl.corporate-info dt {
  display: block;
  float: left;
  clear: left;
  width: 7em;
}
#main-contents dl.corporate-info dd {
  margin: 0 0 1em 7em;
}
#main-contents .entries .entry {
  border-bottom: 1px dotted #cccccc;
  margin-bottom: 1em;
}
#main-contents #side {
  width: 25%;
  float: left;
  padding-top: 2.3em;
}
#main-contents #side .portlet {
  margin-bottom: 2em;
}
#main-contents #side .portlet .portletHeader {
  visibility: hidden;
}
#main-contents #side .portlet .navTree {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #cccccc;
}
#main-contents #side .portlet .navTree .navTreeItem {
  padding: 0.8em 0;
  border-bottom: 1px solid #cccccc;
  position: relative;
  padding-left: 10px;
  margin-bottom: 0;
}
#main-contents #side .portlet .navTree .navTreeItem:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
}
#main-contents #side .portlet .navTree .navTreeCurrentNode:before {
  border-left-color: #18ab42;
}
.section-contactform .documentFirstHeading {
  display: none;
}
.section-contactform #content-core {
  margin: 0 auto;
  width: 80%;
}
.section-contactform #content-core .field {
  margin-bottom: 1.5em;
}
.section-contactform #content-core .fieldErrorBox {
  color: #c4183c;
}
.section-contactform #content-core label {
  font-weight: 600;
}
.section-contactform #content-core input[type=text] {
  padding: 7px 15px;
  width: 60%;
}
.section-contactform #content-core textarea {
  width: 80%;
  height: 10em;
}
.section-contactform #content-core input[type=submit] {
  width: 9em;
  padding: 10px 15px;
  background-image: linear-gradient(#69af6f, #88b04b, #69af6f);
  color: #ffffff;
  border: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.section-contactform #content-core input[type=reset] {
  width: 9em;
  padding: 10px 15px;
  background-image: linear-gradient(#cccccc, #eeeeee, #cccccc);
  border: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  #main-contents {
    margin: 0 auto;
    padding: 0;
    width: 96%;
  }
}
@media screen and (max-width: 640px) {
  #main-contents .boxes {
    flex-direction: column;
  }
  #main-contents .boxes .box2,
  #main-contents .boxes .box3,
  #main-contents .boxes .box4 {
    flex: 1;
  }
  #main-contents #side {
    float: none;
    margin: 0 auto;
    width: 96%;
    padding-top: 2em;
  }
}
#footer {
  margin: 0 auto;
  padding: 30px 15px;
  max-width: 1280px;
  background-color: #ffffff;
  color: #666666;
  text-align: center;
  clear: both;
}
#footer p {
  padding-bottom: 28px;
}
@media screen and (max-width: 640px) {
  #footer {
    text-align: left;
  }
}
/*# sourceMappingURL=++theme++ricerco/less/theme-compiled.less.map */